import cx from 'classnames'

import { useCallback } from 'react'

import { Image } from '@/legacy/components/Image'
import { useSelectIconEvent } from '@/legacy/core/analytics/hooks'
import type { TitleProps } from '@/legacy/models'

import type { BuilderImage } from '@/legacy/models/BuilderImage'

import type { Space } from '../Container'
import { BodyM, HeadingM } from '../Typography'

import { ContentAppInstall, SectionApp, SectionQrCode } from './styles'

export interface AppInstallBlockProps {
  title: TitleProps
  space?: Space
  labelApp?: string
  labelCode?: string
  image?: BuilderImage
}

export const urlImageApp = {
  appleStore:
    'https://cdn.builder.io/api/v1/image/assets%2Fb212bb18f00a40869a6cd42f77cbeefc%2F56e22eed06e648bc8d92962efbd22345',
  playStore:
    'https://cdn.builder.io/api/v1/image/assets%2Fb212bb18f00a40869a6cd42f77cbeefc%2F4ebb08077c1242328da94ce4fe009d3a',
  qrCode:
    'https://cdn.builder.io/api/v1/image/assets%2Fb212bb18f00a40869a6cd42f77cbeefc%2Feb872f566fec400eb81631170bbb25bc',
}
const SectionAppInstall = ({ title, space, labelApp, labelCode, image }: AppInstallBlockProps) => {
  const selectIconEvent = useSelectIconEvent()

  const handleDownloadApp = useCallback(
    (storeName: string) => {
      selectIconEvent({
        label: `baixar-app-serasa-${storeName}`,
      })
    },
    [selectIconEvent],
  )

  return (
    <ContentAppInstall className={cx('ecs-container', `eu-sm-${space}`)}>
      <div className="section__app">
        {title.text && <HeadingM tag={title.tag || 'h3'}>{title.text}</HeadingM>}
        <SectionApp>
          {labelApp && <BodyM aria-hidden>{labelApp}</BodyM>}
          <ul className="list-app">
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=br.com.serasaexperian.consumidor"
                onClick={() => handleDownloadApp('play-store')}
                aria-label="Baixe nosso aplicativo na Google Play Store, abre nova guia em site externo"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image source={urlImageApp.playStore} width={136} height={48} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://apps.apple.com/br/app/serasa/id1102452668"
                onClick={() => handleDownloadApp('apple-store')}
                aria-label="Baixe nosso aplicativo na App Store, abre nova guia em site externo"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image source={urlImageApp.appleStore} width={136} height={48} alt="" />
              </a>
            </li>
          </ul>
        </SectionApp>
        <SectionQrCode>
          {labelCode && <BodyM dark="high">{labelCode}</BodyM>}
          <Image
            width={128}
            height={128}
            alt="QR Code para baixar o aplicativo"
            source={urlImageApp.qrCode}
          />
        </SectionQrCode>
      </div>
      <div className="section__app-img">
        <Image source={image?.url} width={480} height={547} alt={image?.alt} />
      </div>
    </ContentAppInstall>
  )
}
export default SectionAppInstall
