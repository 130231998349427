import type { Space } from '@/legacy/components/Container'
import SectionAppInstall from '@/legacy/components/SectionAppInstall/SectionAppInstall'

import type { TitleProps } from '@/legacy/models'
import type { BuilderImage } from '@/legacy/models/BuilderImage'

export interface AppInstallBlockProps {
  title: TitleProps
  space: Space
  labelApp?: string
  labelCode?: string
  image?: BuilderImage
}
const SectionAppInstallBlock = ({
  title,
  space,
  labelApp,
  labelCode,
  image,
}: AppInstallBlockProps) => (
  <SectionAppInstall
    title={title}
    space={space}
    image={image}
    labelApp={labelApp}
    labelCode={labelCode}
  />
)
export default SectionAppInstallBlock
