import styled from '@emotion/styled'

import { CONFIG } from '@/ui/shared/styles'

export const ContentAppInstall = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-top: 2rem;

  ${CONFIG.media.mobileMd} {
    display: flex;
    flex-direction: column;
  }

  .section__app {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2rem;

    ${CONFIG.media.mobileMd} {
      margin-top: 2rem;
    }
    p {
      font-weight: 100;
    }
  }

  .section__app-img {
    display: flex;
    align-items: end;
    ${CONFIG.media.mobileMd} {
      max-width: 22rem;
      align-self: center;
    }
  }
`

export const SectionApp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .list-app {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`

export const SectionQrCode = styled.div`
  p {
    margin-bottom: 0.75rem;
  }
  ${CONFIG.media.mobileMd} {
    display: none;
  }
`
